<template>
  <component :is="item === undefined ? 'div' : 'b-card'">
    <b-overlay
      :show="item === null"
      rounded="sm"
    >
      <div
        v-if="item"
        class="mt-2 pt-75"
      >
        <b-form>
          <b-tabs pills>
            <b-tab :title="$t('administration.products.tabs.general.label')">
              <b-tabs>
                <b-tab
                  v-for="language in languages"
                  :key="language.code"
                >
                  <template #title>
                    <b-img
                      :src="require('@/assets/images/flags/' + language.code + '.svg')"
                      height="16"
                      width="16"
                      class="mr-1"
                    />
                    <span class="d-none d-sm-inline">{{ language.title }}</span>
                  </template>
                  <b-row>
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <b-form-group
                        :label="$t('form.title.label')"
                        :label-for="'title.' + language.code"
                      >
                        <b-form-input
                          :id="'title.' + language.code"
                          v-model="item.title[language.code]"
                          :state="errors && errors['title.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['title.' + language.code]">
                          {{ errors['title.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <b-form-group
                        :label="$t('form.meta_h1.label')"
                        :label-for="'meta_h1.' + language.code"
                      >
                        <b-form-input
                          :id="'meta_h1.' + language.code"
                          v-model="item.meta_h1[language.code]"
                          :state="errors && errors['meta_h1.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['meta_h1.' + language.code]">
                          {{ errors['meta_h1.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <b-form-group
                        :label="$t('form.meta_title.label')"
                        :label-for="'meta_title.' + language.code"
                      >
                        <b-form-input
                          :id="'meta_title.' + language.code"
                          v-model="item.meta_title[language.code]"
                          :state="errors && errors['meta_title.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['meta_title.' + language.code]">
                          {{ errors['meta_title.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <b-form-group
                        :label="$t('form.meta_description.label')"
                        :label-for="'meta_description.' + language.code"
                      >
                        <b-form-textarea
                          :id="'meta_description.' + language.code"
                          v-model="item.meta_description[language.code]"
                          :state="errors && errors['meta_description.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['meta_description.' + language.code]">
                          {{ errors['meta_description.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <b-form-group
                        :label="$t('form.meta_keywords.label')"
                        :label-for="'meta_keywords.' + language.code"
                      >
                        <b-form-textarea
                          :id="'meta_keywords.' + language.code"
                          v-model="item.meta_keywords[language.code]"
                          :state="errors && errors['meta_keywords.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['meta_keywords.' + language.code]">
                          {{ errors['meta_keywords.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <b-form-group
                        :label="$t('form.canonical.label')"
                        :label-for="'canonical.' + language.code"
                      >
                        <b-form-input
                          :id="'canonical.' + language.code"
                          v-model="item.canonical[language.code]"
                          :state="errors && errors['canonical.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['canonical.' + language.code]">
                          {{ errors['canonical.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <b-form-group
                        :label="$t('form.description.label')"
                        :label-for="'description.' + language.code"
                        :state="errors && errors['description.' + language.code] ? false : null"
                      >
                        <quill-editor
                          :id="'description.' + language.code"
                          v-model="item.description[language.code]"
                          :options="editorOptions"
                          :state="errors && errors['description.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['description.' + language.code]">
                          {{ errors['description.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-tab>
              </b-tabs>
            </b-tab>
            <b-tab :title="$t('administration.products.tabs.data.label')">
              <b-row>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.slug.label')"
                    label-for="slug"
                  >
                    <b-form-input
                      id="slug"
                      v-model="item.slug"
                      :state="errors && errors.slug ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.slug">
                      {{ errors.slug[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.status.label')"
                    label-for="status"
                    :state="errors && errors.status ? false : null"
                  >
                    <v-select
                      id="status"
                      v-model="item.status"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="statusOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="status"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.status">
                      {{ errors.status[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.indexing_status.label')"
                    label-for="indexing_status"
                    :state="errors && errors.indexing_status ? false : null"
                  >
                    <v-select
                      id="indexing_status"
                      v-model="item.indexing_status"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="statusOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="indexing_status"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.indexing_status">
                      {{ errors.indexing_status[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="2"
                >
                  <b-form-group
                    :label="$t('form.started_at.label')"
                    label-for="started_at"
                    :state="errors && errors.started_at ? false : null"
                  >
                    <b-form-datepicker
                      id="started_at"
                      v-model="item.started_at"
                      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.started_at">
                      {{ errors.started_at[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="2"
                >
                  <b-form-group
                    :label="$t('form.ended_at.label')"
                    label-for="ended_at"
                    :state="errors && errors.ended_at ? false : null"
                  >
                    <b-form-datepicker
                      id="ended_at"
                      v-model="item.ended_at"
                      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.ended_at">
                      {{ errors.ended_at[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.type.label')"
                    label-for="type"
                    :state="errors && errors.type ? false : null"
                  >
                    <v-select
                      id="type"
                      v-model="item.type"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="typeOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="type"
                      @input="changeType"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.type">
                      {{ errors.type[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  v-if="item.type !== 'gift'"
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.value.label')"
                    label-for="value"
                  >
                    <b-form-input
                      id="value"
                      v-model="item.value"
                      :state="errors && errors.value ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.value">
                      {{ errors.value[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  v-if="item.type === 'gift'"
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.product.label')"
                    label-for="product_id"
                    :state="errors && errors.product_id ? false : null"
                  >
                    <v-select
                      id="product_id"
                      v-model="item.product_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="productOptions"
                      :reduce="val => val.value"
                      input-id="product_id"
                      :searchable="true"
                      @search="searchProduct"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.product_id">
                      {{ errors.product_id[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.position.label')"
                    label-for="value"
                  >
                    <b-form-input
                      id="position"
                      v-model="item.position"
                      type="number"
                      :state="errors && errors.position ? false : null"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.position">
                      {{ errors.position[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab :title="$t('administration.products.tabs.images.label')">
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                >
                  <!-- media -->
                  <b-media no-body>
                    <b-media-aside>
                      <b-link>
                        <b-img
                          ref="previewEl"
                          rounded
                          :src="$options.filters.mediaUrl(item, 'avatar', '110x110')"
                          height="80"
                        />
                      </b-link>
                      <!--/ avatar -->

                      <!-- reset -->
                      <b-button
                        v-if="item.avatar"
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        variant="outline-secondary"
                        size="sm"
                        class="mb-75 mr-75"
                        @click="item.avatar = null"
                      >
                        {{ $t('general.reset') }}
                      </b-button>
                      <!--/ reset -->
                    </b-media-aside>

                    <b-media-body class="mt-75 ml-75">
                      <InitFileManager
                        field="avatar"
                        type="sale_avatar"
                        :multiple="false"
                        @fm-selected="selectedAvatar"
                      />

                      <b-row
                        v-if="errors && errors.avatar"
                      >
                        <b-col cols="12">
                          <b-form-invalid-feedback :state="false">
                            {{ errors.avatar[0] }}
                          </b-form-invalid-feedback>
                        </b-col>
                      </b-row>
                      <b-row
                        v-if="errors && errors['avatar.type']"
                      >
                        <b-col cols="12">
                          <b-form-invalid-feedback :state="false">
                            {{ errors['avatar.type'][0] }}
                          </b-form-invalid-feedback>
                        </b-col>
                      </b-row>

                    </b-media-body>
                  </b-media>
                  <!--/ media -->
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <hr>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab
              :title="$t('administration.sales.tabs.categories.label')"
              @click="loadCategoryTab"
            >
              <div class="repeater-form">
                <b-row
                  v-for="(category, index) in item.categories"
                  :id="'category' + index"
                  :key="index"
                  ref="row"
                >
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="$t('form.categories.label')"
                      :label-for="'categories.' + index + '.id'"
                      :state="errors && errors['categories.' + index + '.id'] ? false : null"
                    >
                      <treeselect
                        :id="'categories.' + index + '.id'"
                        v-model="category.id"
                        :multiple="false"
                        :options="categoryOptions"
                        :normalizer="normalizer"
                      />
                      <b-form-invalid-feedback v-if="errors && errors['categories.' + index + '.id']">
                        {{ errors['categories.' + index + '.id'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <!-- Remove Button -->
                  <b-col
                    lg="2"
                    md="3"
                    class="mb-50"
                  >
                    <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      class="mt-0 mt-md-2"
                      @click="removeCategory(index)"
                    >
                      <feather-icon
                        icon="XIcon"
                        class="mr-25"
                      />
                      <span>{{ $t('general.delete') }}</span>
                    </b-button>
                  </b-col>
                  <b-col cols="12">
                    <hr>
                  </b-col>
                </b-row>
              </div>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="addCategory"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                <span>{{ $t('general.add') }}</span>
              </b-button>

              <b-row
                v-if="errors && errors.categories"
              >
                <b-col cols="12">
                  <b-form-invalid-feedback :state="false">
                    {{ errors.categories[0] }}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12">
                  <hr>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab :title="$t('administration.sales.tabs.products.label')">
              <div class="repeater-form">
                <b-row
                  v-for="(product, index) in item.products"
                  :id="'product' + index"
                  :key="index"
                  ref="row"
                >
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="$t('form.products.label')"
                      :label-for="'products.' + index + '.id'"
                      :state="errors && errors['products.' + index + '.id'] ? false : null"
                    >
                      <v-select
                        :id="'products.' + index + '.id'"
                        v-model="product.id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="productOptions"
                        :reduce="val => val.value"
                        :input-id="'products.' + index + '.id'"
                        :searchable="true"
                        @search="searchProduct"
                      />
                      <b-form-invalid-feedback v-if="errors && errors['products.' + index + '.id']">
                        {{ errors['products.' + index + '.id'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <!-- Remove Button -->
                  <b-col
                    lg="2"
                    md="3"
                    class="mb-50"
                  >
                    <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      class="mt-0 mt-md-2"
                      @click="removeProduct(index)"
                    >
                      <feather-icon
                        icon="XIcon"
                        class="mr-25"
                      />
                      <span>{{ $t('general.delete') }}</span>
                    </b-button>
                  </b-col>
                  <b-col cols="12">
                    <hr>
                  </b-col>
                </b-row>
              </div>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="addProduct"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                <span>{{ $t('general.add') }}</span>
              </b-button>

              <b-row
                v-if="errors && errors.products"
              >
                <b-col cols="12">
                  <b-form-invalid-feedback :state="false">
                    {{ errors.products[0] }}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12">
                  <hr>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>

          <!-- Action Buttons -->
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="onSubmit"
          >
            {{ $t('general.save') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            :to="{ name: 'sales-index' }"
          >
            {{ $t('general.cancel') }}
          </b-button>
        </b-form>

      </div>
    </b-overlay>
  </component>
</template>

<script>
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor, Quill } from 'vue-quill-editor'
import htmlEditButton from 'quill-html-edit-button'
import EditorMixin from '@/mixins/EditorMixin'

import {
  BTab,
  BTabs,
  BCard,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BFormInvalidFeedback,
  BMedia,
  BMediaBody,
  BMediaAside,
  BLink,
  BImg,
  BFormFile,
  BFormTextarea,
  BFormDatepicker,
  BOverlay,
} from 'bootstrap-vue'
import router from '@/router'
import _ from 'lodash'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { mapGetters } from 'vuex'

import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import CategoryMixin from '@/mixins/CategoryMixin'
import StatusMixin from '@/mixins/StatusMixin'
import SweetAlertMixin from '@/mixins/SweetAlertMixin'
import InitFileManager from '@/components/InitFileManager'

Quill.register('modules/htmlEditButton', htmlEditButton)

export default {
  directives: {
    Ripple,
  },
  components: {
    BTab,
    BTabs,
    BCard,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BFormInvalidFeedback,
    vSelect,
    BMedia,
    BMediaBody,
    BMediaAside,
    BLink,
    BImg,
    BFormFile,
    BFormTextarea,
    quillEditor,
    Treeselect,
    BFormDatepicker,
    BOverlay,
    InitFileManager,
  },
  mixins: [CategoryMixin, SweetAlertMixin, StatusMixin, EditorMixin],
  data() {
    return {
      item: null,
      languages: [],
      categoryOptions: [],
      productOptions: [],
      typeOptions: [
        { label: this.$t('general.sale_types.fixed'), value: 'fixed' },
        { label: this.$t('general.sale_types.percent'), value: 'percent' },
        { label: this.$t('general.sale_types.gift'), value: 'gift' },
      ],
    }
  },
  computed: {
    ...mapGetters({
      errors: 'validation/errors',
    }),
  },
  async beforeCreate() {
    await this.$http.get('/api/languages')
      .then(response => {
        this.languages = response.data.data
      })

    this.item = await this.transformData()
  },
  destroyed() {
    this.$store.dispatch('validation/clearErrors')
  },
  methods: {
    changeType() {
      this.item.value = null
    },
    selectedAvatar(data) {
      this.item.avatar = data
    },
    async searchProduct(query) {
      await this.$http.get('/api/administration/products', {
        params: {
          query,
          ...{ status: 'enabled' },
        },
      })
        .then(response => {
          this.productOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
        })
    },
    async loadCategoryTab() {
      if (!Object.keys(this.categoryOptions).length) {
        await this.$http.get('/api/administration/categories', {
          params: {
            type: 'product',
            status: 'enabled',
          },
        })
          .then(response => {
            this.categoryOptions = response.data
          })
      }
    },
    async onSubmit() {
      const data = {
        title: this.item.title,
        type: this.item.type,
        product_id: this.item.product_id,
        value: this.item.value,
        slug: this.item.slug,
        started_at: this.item.started_at,
        ended_at: this.item.ended_at,
        categories: this.item.categories,
        products: this.item.products,
        avatar: this.item.avatar,
        status: this.item.status,
        position: this.item.position ?? 0,
        description: this.item.description,
        meta_description: this.item.meta_description,
        meta_h1: this.item.meta_h1,
        meta_keywords: this.item.meta_keywords,
        meta_title: this.item.meta_title,
        canonical: this.item.canonical,
        indexing_status: this.item.indexing_status,
      }

      this.$http.post('/api/administration/sales', data)
        .then(() => {
          router.replace({ name: 'sales-index' })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: error.response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
        })
    },
    addCategory() {
      this.item.categories.push({
        id: null,
      })
    },
    removeCategory(index) {
      this.item.categories.splice(index, 1)
    },
    addProduct() {
      this.item.products.push({
        id: null,
      })
    },
    removeProduct(index) {
      this.item.products.splice(index, 1)
    },
    transformData() {
      const data = {
        slug: null,
        product_id: null,
        status: 'enabled',
        type: 'fixed',
        started_at: new Date(),
        ended_at: new Date(),
        value: null,
        avatar: null,
        position: 0,
        categories: [],
        products: [],
        title: {},
        description: {},
        meta_description: {},
        meta_h1: {},
        meta_keywords: {},
        meta_title: {},
        canonical: {},
        indexing_status: 'enabled',
      }

      _.each(this.languages, language => {
        data.title[language.code] = null
        data.description[language.code] = null
        data.meta_description[language.code] = null
        data.meta_h1[language.code] = null
        data.meta_keywords[language.code] = null
        data.meta_title[language.code] = null
        data.canonical[language.code] = null
      })

      return data
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
